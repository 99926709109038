<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        md="3"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="getUser.profileUrl"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="">
                {{ getUser.firstName + " " + getUser.lastName }}
              </h4>
              <b-badge :variant="statusVariant(getUser.status).color">
                {{ statusVariant(getUser.status).text }}
              </b-badge>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{
                  name: 'user-edit',
                  params: { id: this.$router.currentRoute.params.id },
                }"
                variant="primary"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              >
                Изменить
              </b-button>
              <b-button
                @click.prevent="deleteUser"
                variant="outline-danger"
                class="ml-1"
              >
                Удалить
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" md="3">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">ID:</span>
            </th>
            <td class="pb-50">
              {{ getUser.id }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Фио:</span>
            </th>
            <td class="pb-50">
              {{ getUser.firstName + " " + getUser.lastName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Почта:</span>
            </th>
            <td class="pb-50">
              {{ getUser.email }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Дата рождения:</span>
            </th>
            <td class="pb-50">
              {{ getUser.birthDate | formatDate }}
            </td>
          </tr>
        </table>
      </b-col>

      <b-col cols="12" md="3">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Дата регистрации:</span>
            </th>
            <td class="pb-50">
              {{ getUser.registrationDate | formatDate }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Дата посл. активности:</span>
            </th>
            <td class="pb-50">
              {{ getUser.lastActiveTime | formatDateHours }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Версия приложения:</span>
            </th>
            <td class="pb-50">
              {{ getUser.appVersion }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Язык:</span>
            </th>
            <td class="pb-50">
              {{ getUser.lang }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col cols="12" md="3">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Статус:</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="statusVariant(getUser.status).color">
                {{ statusVariant(getUser.status).text }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Тип пользователя:</span>
            </th>
            <td class="pb-50">
              {{ getUser.userType }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Имя папки:</span>
            </th>
            <td class="pb-50">
              {{ getUser.folderName }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    BBadge,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {};
  },
  methods: {
    statusVariant(status) {
      if (status === "ACTIVE")
        return {
          color: "light-success",
          text: "Активный",
        };
      if (status === "DELETED")
        return {
          color: "light-danger",
          text: "Удаленный",
        };
      if (status === "BLOCKED")
        return {
          color: "light-warning",
          text: "Заблокированный",
        };
      if (status === "PENDING")
        return {
          color: "light-secondary",
          text: "В ожидании",
        };
      return {
        color: "light-primary",
        text: "Непонятно",
      };
    },
    async deleteUser() {
      this.$bvModal
        .msgBoxConfirm("Вы уверены что хотите удалить?", {
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: "Удалить",
          cancelTitle: "Отмена",
        })
        .then((value) => {
          if (value === true) {
            axiosIns
              .delete("v1/user/delete", {
                params: {
                  userId: this.$router.currentRoute.params.id,
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: `Успешно`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Пользователь был успешно удален!`,
                      },
                    },
                    {
                      position: "top-right",
                    }
                  );
                  this.$store.dispatch(
                    "fetchUser",
                    this.$router.currentRoute.params.id
                  );
                }
              })
              .catch((error) => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Ошибка`,
                      icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                      variant: "danger",
                      text: error.response.data.errorMessage,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                }
              })
              .finally(() => {
                this.$router.push({ name: "users" });
              });
          }
        });
    },
  },
};
</script>

<style></style>
