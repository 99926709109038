var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"px-2 py-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"\n              d-flex\n              res-flex-column res-align-items-start\n              justify-content-between\n            "},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[(_vm.rows.length)?_c('h4',{staticClass:"mb-0"},[_vm._v(" Предложения ("+_vm._s(_vm.rows.length)+") ")]):_c('h4',{staticClass:"mb-0"},[_vm._v("Предложения")])]),(_vm.selectedRows && _vm.selectedRows.length > 0)?_c('div',{staticClass:"res-w-100 ml-2"},[_c('b-dropdown',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"res-mt-2 res-w-100",attrs:{"id":"dropdown-1","right":"","text":"Редактировать выбранные","variant":"primary","menu-class":"w-100"}},_vm._l((_vm.offerStatuses),function(item,i){return _c('b-dropdown-item',{key:i,on:{"click":function($event){$event.preventDefault();return _vm.editSelectedRows(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1):_vm._e()]),_c('div',{staticClass:"\n                d-flex\n                res-flex-column res-users-list-search\n                align-items-center\n                res-mt-2\n              "},[_c('div',{staticClass:"res-m-0 res-w-100"})])])])],1)],1),(_vm.rows && _vm.rows.length > 0)?_c('vue-good-table',{staticClass:"position-relative custom-scroll",attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":false,"select-options":{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      }},on:{"on-sort-change":_vm.onSortChange,"on-selected-rows-change":_vm.onSelectChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap text-nowrap"},[_vm._v(_vm._s(props.row.id))])]):(props.column.field === 'titleRu')?_c('span',{staticClass:"text-nowrap"},[(props.row.titleRu)?_c('router-link',{attrs:{"to":{
              name: 'offer-edit',
              params: { id: props.row.id },
            }}},[_c('span',{staticClass:"text-nowrap font-weight-bold text-nowrap"},[_c('span',[_vm._v(" "+_vm._s(props.row.titleRu)+" ")])])]):_c('span',[_vm._v(" Имя не указано ")])],1):(props.column.field === 'daysCountTillTheEnd')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap text-nowrap"},[_vm._v(" "+_vm._s(props.row.daysCountTillTheEnd)+" дней ")])]):(props.column.field === 'dateCreated')?_c('span',{staticClass:"text-nowrap"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.formattedRow.dateCreated))+" ")])]):(props.column.field === 'isGiftable')?_c('span',{staticClass:"text-nowrap"},[(props.row.isGiftable == 1)?_c('span',[_vm._v(" Подарочный ")]):_c('span',[_vm._v("Неподарочный")])]):(props.column.field === 'offerType')?_c('span',[_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.typeVariant(props.row.offerType).text)+" ")])],1):(props.column.field === 'offerState')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.offerState).color}},[_vm._v(" "+_vm._s(_vm.statusVariant(props.row.offerState).text)+" ")])],1):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                  name: 'offer-edit',
                  params: { id: props.row.id },
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Изменить")])],1),_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.deleteUser(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Удалить")])],1)],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between px-2 pb-2 pt-1 flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50 select-size-sm",attrs:{"options":_vm.pages,"clearable":false},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":_vm.offerList.length,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,1515592106)},[_c('template',{slot:"emptystate"},[_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(" Нет данных ")])])])],2):_c('div',{staticClass:"px-2 pb-2"},[_c('span',[_vm._v(" Пусто ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }