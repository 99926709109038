<template>
    <b-card class="text-center">
      <b-card-title class="text-left">Описание</b-card-title>
      <b-tabs pills nav-class="">
        <b-tab title="RU" active>
          <b-card-text class="text-left">
            {{ getMerchant.vendorDescriptionRu }}
          </b-card-text>
        </b-tab>
        <b-tab title="UZ">
          <b-card-text class="text-left">
            {{ getMerchant.vendorDescriptionUz }}
          </b-card-text>
        </b-tab>
        <b-tab title="En">
          <b-card-text class="text-left">
            {{ getMerchant.vendorDescriptionEn }}
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BTab, BTabs, BCardText, BCardTitle
} from 'bootstrap-vue'
import { mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    BCardText,
    BCardTitle,
  },
  computed: {
    ...mapGetters(["getMerchant"]),
  },
};
</script>

<style lang="scss" scoped></style>
