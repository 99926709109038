<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="px-2 py-1">
        <b-row>
          <b-col md="12">
            <div
              class="
                d-flex
                res-flex-column res-align-items-start
                justify-content-between
              "
            >
              <div class="d-flex align-items-center">
                <div>
                  <h4 class="mb-0" v-if="rows.length">
                    Предложения ({{ rows.length }})
                  </h4>
                  <h4 class="mb-0" v-else>Предложения</h4>
                </div>
                <div
                  class="res-w-100 ml-2"
                  v-if="selectedRows && selectedRows.length > 0"
                >
                  <b-dropdown
                    class="res-mt-2 res-w-100"
                    id="dropdown-1"
                    right
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    text="Редактировать выбранные"
                    variant="primary"
                    menu-class="w-100"
                  >
                    <b-dropdown-item
                      v-for="(item, i) in offerStatuses"
                      :key="i"
                      @click.prevent="editSelectedRows(item)"
                    >
                      {{ item.title }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>

              <div
                class="
                  d-flex
                  res-flex-column res-users-list-search
                  align-items-center
                  res-mt-2
                "
              >
                <div class="res-m-0 res-w-100">
                  <!-- basic search -->
                  <!-- <b-input-group class="input-group-merge res-w-100 res-mt-2">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                    size="sm"
                      class="res-w-100"
                      @input="handleSearch"
                      v-model="searchTerm"
                      placeholder="Поиск"
                    />
                  </b-input-group> -->
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <vue-good-table
        v-if="rows && rows.length > 0"
        class="position-relative custom-scroll"
        :columns="columns"
        :rows="rows"
        :rtl="false"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        @on-sort-change="onSortChange"
        @on-selected-rows-change="onSelectChange"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'id'" class="text-nowrap">
            <span class="text-nowrap text-nowrap">{{ props.row.id }}</span>
          </span>

          <!-- Column: Name -->
          <span
            v-else-if="props.column.field === 'titleRu'"
            class="text-nowrap"
          >
            <router-link
              v-if="props.row.titleRu"
              :to="{
                name: 'offer-edit',
                params: { id: props.row.id },
              }"
            >
              <span class="text-nowrap font-weight-bold text-nowrap">
                <span>
                  {{ props.row.titleRu }}
                </span>
              </span>
            </router-link>

            <span v-else> Имя не указано </span>
          </span>

          <!-- Column: daysCountTillTheEnd -->
          <span
            v-else-if="props.column.field === 'daysCountTillTheEnd'"
            class="text-nowrap"
          >
            <span class="text-nowrap text-nowrap">
              {{ props.row.daysCountTillTheEnd }} дней
            </span>
          </span>

          <!-- Column: dateCreated -->
          <span
            v-else-if="props.column.field === 'dateCreated'"
            class="text-nowrap"
          >
            <span>
              {{ props.formattedRow.dateCreated | formatDate }}
            </span>
          </span>

          <!-- Column: isGiftable -->
          <span
            v-else-if="props.column.field === 'isGiftable'"
            class="text-nowrap"
          >
            <span v-if="props.row.isGiftable == 1"> Подарочный </span>
            <span v-else>Неподарочный</span>
          </span>

          <!-- Column: offerType -->
          <span v-else-if="props.column.field === 'offerType'">
            <b-badge variant="light-primary">
              {{ typeVariant(props.row.offerType).text }}
            </b-badge>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'offerState'">
            <b-badge :variant="statusVariant(props.row.offerState).color">
              {{ statusVariant(props.row.offerState).text }}
            </b-badge>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'offer-edit',
                    params: { id: props.row.id },
                  }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Изменить</span>
                </b-dropdown-item>
                <b-dropdown-item @click.prevent="deleteUser(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Удалить</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between px-2 pb-2 pt-1 flex-wrap">
            <div class="d-flex align-items-center">
              <v-select
                v-model="pageLength"
                :options="pages"
                :clearable="false"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
                class="per-page-selector d-inline-block mx-50 select-size-sm"
              />
            </div>

            <!-- pagination -->
            <div>
              <b-pagination
                :value="1"
                :total-rows="offerList.length"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <template slot="emptystate">
          <div class="text-center">
            <span> Нет данных </span>
          </div>
        </template>
      </vue-good-table>
      <div v-else class="px-2 pb-2">
        <span> Пусто </span>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    ToastificationContent,
    Ripple,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
  },
  props: {
    offerList: {
      type: Array,
      default: () => [],
    },
  },
  directives: {
    Ripple,
  },
  computed: {
    statusVariant() {
      const statusVariants = {
        /* eslint-disable key-spacing */
        ACTIVE: {
          color: "light-success",
          text: "Активный",
        },
        DELETED: {
          color: "light-danger",
          text: "Удаленный",
        },
        EXPIRED: {
          color: "light-warning",
          text: "Истекший",
        },
        SUSPENDED: {
          color: "light-secondary",
          text: "Приостановленный",
        },
        /* eslint-enable key-spacing */
      };

      return (status) => statusVariants[status];
    },
    typeVariant() {
      const typeVariants = {
        /* eslint-disable key-spacing */
        CERTIFICATE: {
          text: "Сертификат",
        },
        INFORMATION: {
          text: "Информационное",
        },
        VAUCHER: {
          text: "Ваучер",
        },
        SPECIAL_CASHBACK: {
          text: "Спец. кэшбэк",
        },
        /* eslint-enable key-spacing */
      };

      return (status) => typeVariants[status];
    },
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      isAddNewUserModalActive: false,
      pageLength: 5,
      currentPage: 1,
      totalCount: null,
      sortField: null,
      field: null,
      fieldValue: null,
      pages: ["3", "5", "10"],
      offerStatuses: [
        {
          title: "Активировать",
          value: "ACTIVE",
        },
        {
          title: "Приостановить",
          value: "SUSPENDED",
        },
        {
          title: "Удалить",
          value: "DELETED",
        },
      ],
      columns: [
        {
          label: "ID",
          field: "id",
          type: "number",
        },
        {
          label: "Название",
          field: "titleRu",
          type: "text",
        },
        {
          label: "Скидка",
          field: "discount",
          type: "number",
        },
        {
          label: "Осталось",
          field: "daysCountTillTheEnd",
          type: "number",
        },
        {
          label: "Тип",
          field: "offerType",
          type: "text",
        },
        {
          label: "Подарочный/Неподарочный",
          field: "isGiftable",
          type: "text",
        },
        {
          label: "Статус",
          field: "offerState",
          type: "text",
        },
        {
          label: "Дейстивие",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      selectedRows: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const response = await axiosIns
        .get(`v1/user/user-offers/${this.$router.currentRoute.params.id}`)
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      this.rows = response.data.data;
      this.totalCount = response.data.data;
    },
    onSelectChange(params) {
      const selectedRowsId = params.selectedRows.map(function (item) {
        return item.id;
      });
      this.selectedRows = selectedRowsId;
    },
    async editSelectedRows(item) {
      axiosIns
        .post("offer/change-offers-state", {
          offerList: this.selectedRows,
          offerState: item.value,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Успешно`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Статус пользователя успешно изменен!`,
                },
              },
              {
                position: "top-right",
              }
            );
            this.getData();
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    handleSearch(searching) {
      this.field = "name";
      this.fieldValue = searching;
      this.getData();
    },
    async handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    async handlePageLengthChange(active) {
      this.pageLength = active;
      this.getData();
    },
    onSortChange(params) {},
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.per-page-selector {
  min-width: 70px;
  max-width: 70px;
  max-height: 36px;
  .vs__dropdown-toggle {
    max-height: 36px;
  }
}
</style>
