var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"px-2 py-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"\n              d-flex\n              res-flex-column res-align-items-start\n              justify-content-between\n            "},[_c('div',[_c('h4',{staticClass:"mb-0"},[_vm._v("Истеченные/Снятые ("+_vm._s(_vm.totalCount)+")")])]),_c('div',{staticClass:"\n                d-flex\n                res-flex-column res-users-list-search\n                align-items-center\n                res-mt-2\n              "},[_c('div',{staticClass:"res-m-0 res-w-100"})])])])],1)],1),_c('vue-good-table',{staticClass:"position-relative",attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"rtl":false,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
      },"select-options":{
        enabled: false,
      },"pagination-options":{
        enabled: true,
      }},on:{"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('router-link',{attrs:{"to":{
              name: 'merchant-view',
              params: { id: props.row.id },
            }}},[_c('span',{staticClass:"text-nowrap font-weight-bold text-nowrap"},[_vm._v(_vm._s(props.row.name))])])],1):(props.column.field === 'dateCreated')?_c('span',{staticClass:"text-nowrap"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.formattedRow.dateCreated))+" ")])]):(props.column.field === 'vendorState')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.vendorState).color}},[_vm._v(" "+_vm._s(_vm.statusVariant(props.row.vendorState).text)+" ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between px-2 pb-2 pt-1 flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50 select-size-sm",attrs:{"options":_vm.pages,"clearable":false},on:{"input":_vm.handlePageLengthChange},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":_vm.totalCount,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.handleChangePage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }